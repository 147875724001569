import React, { useState, useContext } from "react";
import Sticky from "react-stickynode";
import { withRouter } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { Button, Drawer } from "antd";
import Logo from "components/UI/Logo/Logo";
import Text from "components/UI/Text/Text";
import TextLink from "components/UI/TextLink/TextLink";
import Navbar from "components/Navbar/Navbar";
import { AuthContext } from "context/AuthProvider";
import { LayoutContext } from "context/LayoutProvider";
import useWindowSize from "library/hooks/useWindowSize";
import { AGENT_PROFILE_PAGE } from "settings/constant";
import AuthMenu from "./AuthMenu";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import ProfileMenu from "./ProfileMenu";
import NavbarBoatSearch from "./NavbarBoatSearch";
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from "./Header.style";
import {
  ImWhatsapp,
  ImTelegram,
  ImInstagram,
  ImFacebook,
  ImMail4,
  ImPhone,
} from "react-icons/im";
import { FaFacebook } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";

const avatarImg = `http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png`;

export default withRouter(function Header({ location }) {
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);

  const sidebarHandler = () => {
    setState(!state);
  };

  const headerType = location.pathname === "/" ? "default" : "default";

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === "transparent" ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                {/* {headerType === "transparent" && <LogoIcon />} */}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/Logo.png"
                  title="Gümbet Watersports"
                />
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={loggedIn}
            avatar={<Logo src={avatarImg} />}
            profileMenu={<ProfileMenu avatar={<Logo src={avatarImg} />} />}
            headerType={headerType}
            searchComponent={<NavbarBoatSearch />}
            location={location}
            searchVisibility={false}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <>
                {/* {headerType === "transparent" && <LogoIcon />} */}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/Logo.png"
                  title="Gümbet Watersports"
                />
              </>
              {/* <NavbarBoatSearch /> */}
            </LogoArea>
            <Button
              className={`hamburg-btn ${state ? "active" : ""}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              visible={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {/* {loggedIn ? (
                <AvatarWrapper>
                  <AvatarImage>
                    <Logo src={avatarImg} />
                  </AvatarImage>
                  <AvatarInfo>
                    <Text as="h3" content="Nova Scotia" />
                    <TextLink
                      link={AGENT_PROFILE_PAGE}
                      content="View Profile"
                    />
                  </AvatarInfo>
                </AvatarWrapper>
              ) : (
                <AuthMenu className="auth-menu" />
              )} */}
              <MobileMenu className="main-menu" />
              <div>
                <p style={{ marginTop: "50px" }}>
                  <TextLink
                    style={{ paddingLeft: "20px" }}
                    link={{ pathname: "tel:+905322065438" }}
                    target="_blank"
                    content={<FiPhoneCall size={28} color="#04AEEE" />}
                  />

                  <TextLink
                    style={{ paddingLeft: "20px" }}
                    link={{
                      pathname:
                        "https://api.whatsapp.com/send?phone=+905322065438" +
                        "&text=I%20am%20Contacting%20your%20from%20" +
                        "gumbetwatersports.com" +
                        "%20website%0D%0ACan%20you%20give%20me%20more%20information%20about%20the%20services",
                    }}
                    target="_blank"
                    content={<ImWhatsapp size={28} color="#128C7E" />}
                  />
                  <TextLink
                    style={{ paddingLeft: "20px" }}
                    link={{ pathname: "https://t.me/zaryachting" }}
                    target="_blank"
                    content={<ImTelegram size={28} color="#1C96D4" />}
                  />
                </p>
              </div>
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
});
