// General Page Section
export const HOME_PAGE = "/";
export const AGENTS_PAGE = "/agents";

// Listing Single Page Section
export const LISTING_POSTS_PAGE = "/products";
export const SINGLE_POST_PAGE = "/product";

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = "/profile";
export const AGENT_ACCOUNT_SETTINGS_PAGE = "/account-settings";
export const AGENT_PROFILE_EDIT_PAGE = "/edit";
export const AGENT_IMAGE_EDIT_PAGE = "/change-image";
export const AGENT_PASSWORD_CHANGE_PAGE = "/change-password";
export const AGENT_PROFILE_DELETE = "/delete";
export const AGENT_PROFILE_FAVOURITE = "/favourite-post";
export const AGENT_PROFILE_CONTACT = "/contact";

// Other Pages
export const PRICING_PLAN_PAGE = "/pricing-plan";
export const PRIVACY_PAGE = "/privacy";
export const ADD_HOTEL_PAGE = "/add-hotel";

// Login / Registration Page
export const LOGIN_PAGE = "/sign-in";
export const REGISTRATION_PAGE = "/sign-up";
export const CHANGE_PASSWORD_PAGE = "/change-password";
export const FORGET_PASSWORD_PAGE = "/forget-password";

export const FIREBASE_SETTINGS = "gws";
export const FIREBASE_PRODUCTS = "gumbet_tours";
export const FIREBASE_ORDERS = "gws_orders";
export const FIREBASE_CUSTOMERS = "gws_customers";
export const QNB_MERCHANTID = "095700000020420";
export const QNB_USERCODE = "gumbetwatersportsapi";
export const QNB_USERPASS = "Lr0Ed";
